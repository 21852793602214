import * as React from "react"

const DeadIco = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M16 2C9.383 2 4 7.383 4 14c0 2.862 1.022 5.629 2.881 7.8.4.538.794 1.007 1.119 1.382V28h16V23.174c.34-.397.688-.819 1.033-1.275A11.983 11.983 0 0 0 28 14c0-6.617-5.383-12-12-12zm7.482 18.634A29.798 29.798 0 0 1 22 22.426V26h-3v-2h-2v2h-2v-2h-2v2h-3v-3.574c-.363-.412-.957-1.064-1.556-1.876A9.959 9.959 0 0 1 6 14C6 8.477 10.477 4 16 4s10 4.477 10 10a9.962 9.962 0 0 1-2.518 6.634zM11.5 12a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7zm0 5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5zm9-5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7zm0 5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5zM16 18l1.732 3h-3.464L16 18z"
    />
  </svg>
)

export default DeadIco
