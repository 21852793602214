import React, { useEffect, useState } from 'react'

export const Score = (props) => {

    const [listScores, setListScores] = useState(<tr><td>Loading...</td></tr>);

    useEffect(() => {
        const interval = setInterval(() => {
            async function fetchApi() {
                const response = await fetch(props.baseUrl + 'board/' + props.boardId + '/score', {
                    headers: {
                        'shipId': props.shipId,
                    }
                })
                const data = await response.json()
                console.log(data.scores);
                setListScores(data.scores
                    .map((score) =>
                        <tr key={score.name} style={{ color: score.color, textAlign: 'right' }}>
                            <td ><strong>{score.name}</strong></td><td className='scorePercent'><strong>{score.scoreString}</strong></td>
                        </tr>)
                )
            }
            fetchApi()
        }, 1000);
        return () => clearInterval(interval);
    }, [props]);

    return (
        <div className="score">
            <table>
                <tbody>
                    {listScores}
                </tbody>
            </table>
        </div>
    );
};
