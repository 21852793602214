import React from 'react'

class GameForm extends React.Component {

    render() {
        return (
            <div className="game-form">
                <form >
                    <label>
                        Board ID:
                        <input className="transparent-input" value={this.props.boardId} onChange={event => this.props.onBoardChange(event)} placeholder='Board UUID' pattern='[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}' />
                    </label>
                    <label>
                        Ship ID:
                        <input className="transparent-input" value={this.props.shipId} onChange={event => this.props.onShipChange(event)} placeholder='Ship UUID' pattern='[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}' />
                    </label>
                </form>
            </div>
        )
    }
}

export default GameForm