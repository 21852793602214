import React, { useEffect, useState } from 'react'
import ShipIco from '../icons/ShipIco'
import FireIco from '../icons/FireIco'
import MineIco from '../icons/MineIco'
import IslandIco from '../icons/IslandIco'
import DeadIco from '../icons/DeadIco'
import FishIco from '../icons/FishIco'

const Row = (props) => {
    let rowBuffer = []

    for (let x = 0; x < props.data.width; x++) {
        rowBuffer.push(<Cell key={props.y + '_' + x} data={props.data} mazeType={props.line.charAt(x)} x={x} y={props.y} />)
    }
    return (
        <tr>
            {rowBuffer}
        </tr>
    )
}

const Cell = (props) => {
    let cellBuf = null

    if (props.mazeType === '2') {
        cellBuf = <MineIco width="50px" height="50px" viewBox="0 0 24 24"/>
    } else if (props.mazeType === '5') {
        cellBuf = <FishIco width="50px" height="50px" viewBox="0 0 122 95"/>
    }

    props.data.ships.forEach(ship => {
        if (ship.x === props.x && ship.y === props.y) {
            cellBuf = <ShipIco fill={ship.color} width="50px" height="50px" viewBox="0 0 290 290"/>
            if (ship.dead) {
                
                cellBuf = <DeadIco stroke={ship.color} fill={ship.color} width="50px" height="50px" viewBox="0 0 30 30"/>
            }
        }
    })

    if (props.x === props.data.entranceX && props.y === props.data.entranceY) {
        console.log('Start possition: ' + props.data.entranceX + ' ' + props.data.entranceY)
        cellBuf = <FireIco stroke="black" width="50px" height="50px" viewBox="0 0 24 24"/>
    }

    if (props.x === props.data.escapeX && props.y === props.data.escapeY) {
        console.log('Exit possition: ' + props.data.escapeX + ' ' + props.data.escapeY)
        cellBuf = <IslandIco stroke="black" width="50px" height="50px" viewBox="0 0 24 24"/>
    }
    
    return (
        <td width='50px' height='50px' >
            {cellBuf}
        </td>
    )
}

export const Board = (props) => {

    const [board, setBoard] = useState(<tr><td>Loading board...</td></tr>);

    useEffect(() => {
        const interval = setInterval(() => {
            async function fetchApi() {
                console.log((props.baseUrl + 'board/' + props.boardId))
                const response = await fetch((props.baseUrl + 'board/' + props.boardId), {
                    headers: {
                        'shipId': props.shipId,
                    }
                })
                
                const data = await response.json()

                console.log(data)
                let buffer = []
                let lines = data.maze.split(/\n/)
                for (let y = 0; y < data.height; y++) {
                    buffer.push(<Row key={y} line={lines[y]} data={data} y={y} />)
                }

                setBoard(
                    buffer
                )
            }
            fetchApi()
        }, 1000);
        return () => clearInterval(interval);
    }, [props]);

    return (
        <div className="board">
            <table>
                <tbody>
                    {board}
                </tbody>
            </table>
        </div>
    );
};
