import * as React from "react"

const ShipIco = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 291.337 291.337"
    
    xmlSpace="preserve"
    {...props}
  >
    <path d="M130.243 89.398a44.858 44.858 0 0 1 15.42-2.724 44.87 44.87 0 0 1 15.421 2.725l51.438 18.763V55.35c0-8.284-6.716-15-15-15h-36.859V15c0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15v25.35h-36.86c-8.284 0-15 6.716-15 15v52.813c9.657-3.524 41.981-15.314 51.44-18.765zM237.629 149.254l-43.413-15.836-43.413-15.836a15.015 15.015 0 0 0-10.282 0l-86.826 31.672c-7.642 2.779-11.759 11.303-8.999 19.098l40 112.991c2.098 5.929 7.768 9.994 14.14 9.994h93.652c6.333 0 12.026-4.022 14.14-9.994l40-112.991c2.892-8.168-1.744-16.46-8.999-19.098z" />
  </svg>
)

export default ShipIco
