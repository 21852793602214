import './App.css';
import { Score } from './game/Score';
import GameForm from './game/GameForm';
import React, { useState } from 'react';
import { Board } from './game/Board';

const regexExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
//const APP_CONFIG = fetch('config.json').then()
const BASE_URL = 'https://marine.szewczyk.it/'
// const BASE_URL = 'http://localhost:8080/'

function App() {
  const [boardId, setBoardId] = useState('boardId')
  const [shipId, setShipId] = useState('shipId')

  if (!regexExp.test(boardId) || !regexExp.test(shipId)) {
    return (
      <div>
        <GameForm
          boardId={boardId}
          shipId={shipId}
          onBoardChange={event => { setBoardId(event.target.value); console.log(event.target.value); }}
          onShipChange={event => { setShipId(event.target.value); console.log(event.target.value); }}
        />
      </div>
    )
  }

  return (
    <div>
      <GameForm
        boardId={boardId}
        shipId={shipId}
        onBoardChange={event => { setBoardId(event.target.value); console.log(event.target.value); }}
        onShipChange={event => { setShipId(event.target.value); console.log(event.target.value); }}
      />
      <Score
        boardId={boardId}
        shipId={shipId}
        baseUrl={BASE_URL}
      />
      <Board
        boardId={boardId}
        shipId={shipId}
        baseUrl={BASE_URL}
      />
    </div>
  );
}

export default App;
