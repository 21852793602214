import * as React from "react"

const FireIco = (props) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#dd2c00",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(.42 0 0 .42 1.92 1.92)"
        d="M39 28c0 8.395-6.606 15-15.001 15S9 36.395 9 28 22.479 12.6 20.959 5C24 5 39 15.841 39 28z"
      />
      <path
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#ff5722",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(.42 0 0 .42 1.92 1.92)"
        d="M33 32c0-7.599-9-15-9-15 0 6.08-9 8.921-9 15 0 5.036 3.963 9 9 9s9-3.964 9-9z"
      />
      <path
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeDashoffset: 0,
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          fill: "#ffc107",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(.42 0 0 .42 1.92 1.92)"
        d="M18.999 35.406C19 32 24 30.051 24 27c0 0 4.999 3.832 4.999 8.406 0 2.525-2.237 4.574-5 4.574s-5.001-2.048-5-4.574z"
      />
    </g>
  </svg>
)

export default FireIco
