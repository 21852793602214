import * as React from "react"

const IslandIco = (props) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g>
      <path
        style={{
          stroke: "#00F",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "round",
          strokeDashoffset: 0,
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          fill: "none",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(1.43 0 0 1.43 1.99 1.99)"
        d="M12.883 13.135h-.453a1.81 1.81 0 0 1-1.81-1.81 1.81 1.81 0 0 1-3.62 0 1.81 1.81 0 0 1-3.62 0 1.81 1.81 0 0 1-1.81 1.81h-.452M9.846 9.282a5.149 5.149 0 0 0-7.952 0"
        strokeLinecap="round"
      />
      <path
        style={{
          stroke: "#0F0",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "round",
          strokeDashoffset: 0,
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          fill: "none",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(1.43 0 0 1.43 1.99 1.99)"
        d="M6.564 7.454c.056-2.12.703-4.052 2.11-4.746M5.87.926a2.42 2.42 0 0 1 2.813 1.782"
        strokeLinecap="round"
      />
      <path
        style={{
          stroke: "#0F0",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "round",
          strokeDashoffset: 0,
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          fill: "none",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(1.43 0 0 1.43 1.99 1.99)"
        d="M12.106 3.055a2.607 2.607 0 0 0-3.432-.347"
        strokeLinecap="round"
      />
      <path
        style={{
          stroke: "#0F0",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "round",
          strokeDashoffset: 0,
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          fill: "none",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(1.43 0 0 1.43 1.99 1.99)"
        d="M5.232 3.712a2.813 2.813 0 0 1 3.442-1.004 2.391 2.391 0 0 1 1.772 2.814"
        strokeLinecap="round"
      />
    </g>
  </svg>
)

export default IslandIco
