import * as React from "react"

const MineIco = (props) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      style={{
        stroke: "none",
        strokeWidth: 1,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeDashoffset: 0,
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        fill: "#000",
        fillRule: "nonzero",
        opacity: 1,
      }}
      transform="matrix(.77 0 0 .77 .45 .45)"
      d="M14.984 1.986A1 1 0 0 0 14 3v1.05A10.942 10.942 0 0 0 7.963 6.55l-.74-.74a1 1 0 1 0-1.414 1.414l.74.74A10.942 10.942 0 0 0 4.05 14H3a1 1 0 1 0 0 2h1.05a10.942 10.942 0 0 0 2.499 6.037l-.74.74a1 1 0 1 0 1.414 1.414l.74-.74A10.942 10.942 0 0 0 14 25.95V27a1 1 0 1 0 2 0v-1.05a10.942 10.942 0 0 0 6.037-2.499l.74.74a1 1 0 1 0 1.414-1.414l-.74-.74A10.942 10.942 0 0 0 25.95 16H27a1 1 0 1 0 0-2h-1.05a10.942 10.942 0 0 0-2.499-6.037l.74-.74a1 1 0 1 0-1.414-1.414l-.74.74A10.942 10.942 0 0 0 16 4.05V3a1 1 0 0 0-1.016-1.014zM12 10a2 2 0 1 1 .001 3.999A2 2 0 0 1 12 10z"
    />
  </svg>
)

export default MineIco
